// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-accreditation-js": () => import("./../../../src/pages/about-us/accreditation.js" /* webpackChunkName: "component---src-pages-about-us-accreditation-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-previous-projects-bore-panel-upgrades-js": () => import("./../../../src/pages/previous-projects/bore-panel-upgrades.js" /* webpackChunkName: "component---src-pages-previous-projects-bore-panel-upgrades-js" */),
  "component---src-pages-previous-projects-chlorine-containers-disinfection-program-js": () => import("./../../../src/pages/previous-projects/chlorine-containers-disinfection-program.js" /* webpackChunkName: "component---src-pages-previous-projects-chlorine-containers-disinfection-program-js" */),
  "component---src-pages-previous-projects-eva-valley-power-station-js": () => import("./../../../src/pages/previous-projects/eva-valley-power-station.js" /* webpackChunkName: "component---src-pages-previous-projects-eva-valley-power-station-js" */),
  "component---src-pages-previous-projects-fuel-transfer-systems-js": () => import("./../../../src/pages/previous-projects/fuel-transfer-systems.js" /* webpackChunkName: "component---src-pages-previous-projects-fuel-transfer-systems-js" */),
  "component---src-pages-previous-projects-index-js": () => import("./../../../src/pages/previous-projects/index.js" /* webpackChunkName: "component---src-pages-previous-projects-index-js" */),
  "component---src-pages-previous-projects-laramba-borefield-power-station-js": () => import("./../../../src/pages/previous-projects/laramba-borefield-power-station.js" /* webpackChunkName: "component---src-pages-previous-projects-laramba-borefield-power-station-js" */),
  "component---src-pages-previous-projects-peppimenarti-power-station-js": () => import("./../../../src/pages/previous-projects/peppimenarti-power-station.js" /* webpackChunkName: "component---src-pages-previous-projects-peppimenarti-power-station-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-services-electrical-js": () => import("./../../../src/pages/services/electrical.js" /* webpackChunkName: "component---src-pages-services-electrical-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-install-service-repair-js": () => import("./../../../src/pages/services/install-service-repair.js" /* webpackChunkName: "component---src-pages-services-install-service-repair-js" */),
  "component---src-pages-services-power-generation-js": () => import("./../../../src/pages/services/power-generation.js" /* webpackChunkName: "component---src-pages-services-power-generation-js" */),
  "component---src-pages-services-remote-services-js": () => import("./../../../src/pages/services/remote-services.js" /* webpackChunkName: "component---src-pages-services-remote-services-js" */),
  "component---src-pages-services-switchboards-js": () => import("./../../../src/pages/services/switchboards.js" /* webpackChunkName: "component---src-pages-services-switchboards-js" */),
  "component---src-pages-services-test-protection-js": () => import("./../../../src/pages/services/test-protection.js" /* webpackChunkName: "component---src-pages-services-test-protection-js" */),
  "component---src-pages-services-water-management-js": () => import("./../../../src/pages/services/water-management.js" /* webpackChunkName: "component---src-pages-services-water-management-js" */)
}

